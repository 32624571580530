import React from "react"
import SEO from "../components/seo"
import { FaArrowRight } from "react-icons/fa"

import "../scss/yougig.scss"

import HeaderLogoImg from "../assets/img/yougig/yougigLogo.png"
import HeaderImg from "../assets/img/yougig/Computer.png"
import YougigLogo from "../assets/img/yougig/yougig.png"
import YouProLogo from "../assets/img/yougig/Youpro.png"
import Img2 from "../assets/img/yougig/img2.png"
import Icon1 from "../assets/img/yougig/Icon1.png"
import Icon2 from "../assets/img/yougig/Icon2.png"
import Icon3 from "../assets/img/yougig/Icon3.png"
import Icon4 from "../assets/img/yougig/Icon4.png"
import Icon5 from "../assets/img/yougig/Icon5.png"
import Icon6 from "../assets/img/yougig/Icon6.png"
import Img3 from "../assets/img/yougig/devJobs.svg"
import TickIcon from "../assets/img/yougig/tick.svg"
import HelicopterImg from "../assets/img/yougig/helicopter.svg"
import SingleForm from "../components/singleForm"

const YougigPage = () => {
  return (
    <div>
      <SEO title="Yougig" keywords={[`youpal`, `youpal group`, `yougig`]} />
      <div className="youMain yougigMain">
        <section className="container internalHeader">
          <div className="col">
            <img src={HeaderLogoImg} className="headerLogo" />
            <div className="internalMainHeader bugP" id="headerTextContainer">
              <h2 className="internalNarrowCol">
              Yougig enables you to build your teams at the speed of <span style={{ color: "#f90077" }}>digital</span>
              </h2>
            </div>
            <div className="headingInternalContainer">
              <p className="internalNarrowCol">
              Get the best talent sourced from all over the globe, using advanced AI technology to match you with the perfect fit.
              </p>
              <img className="headerImg" src={HeaderImg} />
            </div>
          </div>
        </section>

        <section className="container internalSolutions">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Our solution for your business
            </h3>
            <p className="internalNarrowCol">
            Yougig is the crossroad for the right moment, people, and places.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={YougigLogo} />
              </div>
              <h4></h4>
              <p>
              Seeking for a great addition to your team? We go over and above to ensure you get the best personnel for your project.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={YouProLogo} />
              </div>
              <h4></h4>
              <p>
              Looking for a job? Get the best opportunities at Youpro.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection internalImage">
          <img className="sideImg" id="benefitsImg" src={Img2} />
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">Benefits</div>
              <h3></h3>
              <p className="exploreSectionSubtitle">
              Yougig is a bridge where clients and experts can meet and work together. We have a unique Immediate Matching process.
              </p>
              <div className="row exploreFeatureWrapper justify-between">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon1} />
                    </div>
                    <div className="col sideContent">
                      <p>
                      We provide full customisation according to your needs and requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon2} />
                    </div>
                    <div className="col sideContent">
                      <p>We have remote and in-house candidates available.</p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon3} />
                    </div>
                    <div className="col sideContent">
                      <p>
                      We prioritise quality above everything.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon4} />
                    </div>
                    <div className="col sideContent">
                      <p>
                      We offer competitive rates based on the candidate's skillset.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon5} />
                    </div>
                    <div className="col sideContent">
                      <p>
                      We assist with quick onboarding and offboarding.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon6} />
                    </div>
                    <div className="col sideContent">
                      <p>We support you from start to finish for a successful project delivery.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection internalWhy">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">Why Yougig?</div>
                <h3>
                We connect you to the best talent no matter your location.
                </h3>
                <p className="mb-5">
                Whatever the size of your business, we find professionals that suit you.
                </p>
                <p className="mb-5">Go ahead:</p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Submit the application
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Make your choice
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Build your job post
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Let the journey begin
                  </div>
                  <div className="col-6">
                    <img className="pl-md-0" src={TickIcon} />
                    Get your match
                  </div>
                </div>
                <div className="buttonArea">
                  <a href="https://yougig.work/">
                    <button type="button" className="btn btn-primary px-4">
                      <span>Find out more</span>
                      <FaArrowRight style={{ fill: "white" }} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={Img3} />
            </div>
          </div>
        </section>

        <section className="container internalSection integrationSection">
          <div className="col">
            <div className="internalSmallSecondaryHeader text-center">
              PRODUCTS
            </div>
            <h3 className="text-center">
              Seamless integrations with other Youpal products
            </h3>
          </div>
        </section>

        <section className="container position-relative">
          <img src={HelicopterImg} alt="contact us" className="helicopterImg" />
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YougigPage
